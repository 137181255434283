.map-container {
  height: 92vh;
}

.form__components__map .map-container {
    height: 360px;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: "Montserrat-Medium";
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

/* body {
  margin: 0;
  padding: 0;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
} */

.marker {
  /* background-image: url("../../images//Dashboard/Location_icon.png"); */
  /* background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%; */
  background-repeat: no-repeat;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: "Montserrat-Medium";
}

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 40px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

.mapboxgl-popup-content {
  width: 327px;
  padding: 0;
  border-radius: 8px;
}

@media (max-width: 769px) {
    .mapboxgl-popup-content{
        width: unset;
    }
}

.Map_popup_chips {
  position: absolute;
  top: 15px;
  font-family: "Montserrat-Medium";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background: #2b63ca;
  padding: 4px 15px;
  z-index: 1;
}

.Map_popup_img {
  /* width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 175px; */
  position: relative;
    background: #E4E1DE;
    top: 40%;
}

.map_popup_have_img{
    width: 327px;
    height: 175px;
    border-radius: 8px 8px 0px 0px;
    position: relative;
    background: #E4E1DE;
}

.Map_popup_img_box{
  height: 175px;
  background: #E4E1DE;
  border-radius: 8px 8px 0px 0px;
}

@media (max-width: 769px) {
  .map_popup_have_img{
    width: 100%;
  }
  .Map_popup_img_box{
    min-width: 240px
  }
}

.Map_popup_detail_box {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.mapboxgl-popup-close-button {
  display: none;
}

.Map_popup_footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 0px;
  background: #efefef;
  border-radius: 0px 0px 8px 8px;
}

.Map_popup_detail_text {
  font-family: "Montserrat-Medium";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #000000;
}

.Map_popup_detail_text1 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.Map_popup_detail_text2 {
  font-family: "Montserrat-Medium";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 8px;
}

.button_map {
  border: none;
  cursor: pointer;
}

.inactive {
    display: none;
}

.Map_popup_MoreDeatail{
  font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
}

.mapboxgl-ctrl-top-right{
  position: fixed !important;
  top: 82px !important;
}

@media (max-width: 769px) {
  .mapboxgl-ctrl-top-right{
    top: 76px !important;
  }
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #efefef !important;
}

.Map_popup_incident_main_box{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 16px;
}

.Map_popup_incident_title{
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 18px;
}

.Map_popup_incident_sec_box{
  display: flex;
  flex-wrap: wrap;
}

.Map_popup_incident_sec_title{
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  margin-bottom: 5px;
}

.Map_popup_incident_status_green{
  display: flex;
  align-items: center;
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #52C865;
  margin-top: 4px;
}

.Map_popup_incident_sec_data{
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}

.Map_popup_additional_box{
  min-height: 40px;
}

.Map_popup_incident_icon{
    width: 13px;
    height: 13px;
    margin-right: 4px;
}

.Map_popup_incident_third_box{
    margin-top: 18px;
    width: 50%;
}

.Map_popup_incident_50W{
  width: 50%;
}

.Map_popup_incident_80W {
  width: 100%;
}

.anontation_main_box{
  /* right: 15px;
  position: absolute;
  z-index: 4;
  bottom: 32px; */
  right: 8px;
    position: absolute;
    z-index: 4;
    bottom: 50px;
}

.incident-form-map .anontation_main_box{
  right: 8px;
  position: absolute;
  z-index: 4;
  bottom: 50px;
}

.anontation_item{
    background: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.anontation_item1{
    background: white;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.anontation_item1_img{
  width: 24px;
  height: 24px;
}

@media (max-width: 425px) {
  .anontation_main_box{
  right: 8px;
  bottom: 55px;
}
}